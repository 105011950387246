export const components = {
	ptBR: {
		contactForm: {
			name: "Nome",
			email: "Email",
			phone: "Telefone",
			subject: "Assunto",
			deadLine: "Prazo",
			budget: "Orçamento",
			message: "Mensagem",
		},
		footer: {
			titles: {
				addresses: [
					{
						addressName: "São Paulo",
						adressStep: "Av. Doutor Cardoso de Melo 878, conjunto 31",
						adressStep2: "Vila Olímpia, São Paulo-SP, 04548-003",
					},
					{
						addressName: "Salvador",
						adressStep: "Av. da França, 393 - 2° andar",
						adressStep2: "Comercio, Salvador - BA, 40010-000",
					},
				],
				home: "Home",
				consulting: "Consultoria",
				customerArea: "Área do Cliente",
				cases: "Cases",
				blog: "Blog",
				methodology: "Metodologia",
				services: "Serviços",
			},
		},
	},
	enUS: {
		contactForm: {
			name: "Name",
			email: "Email",
			phone: "Phone",
			subject: "Subject",
			deadLine: "Deadline",
			budget: "Budget",
			message: "Message",
		},
		footer: {
			titles: {
				addresses: [
					{
						addressName: "São Paulo",
						adressStep: "Av. Doutor Cardoso de Melo 878, conjunto 31",
						adressStep2: "Vila Olímpia, São Paulo-SP, 04548-003",
					},
					{
						addressName: "Salvador",
						adressStep: "Av. da França, 393 - 2° andar",
						adressStep2: "Comercio, Salvador - BA, 40010-000",
					},
				],
				home: "Home",
				customerArea: "Customer's Area",
				cases: "Cases",
				consulting: "Consulting",
				blog: "Blog",
				methodology: "Methodology",
				services: "Services",
			},
		},
	},
};
