export const error = {
	ptBR: {
		needLongerName: (numOfChars: number) => `Nome precisa ser maior que ${numOfChars} caracteres.`,
		default: "Um erro ocorreu, tente mais tarde.",
		title: "Página não encontrada",
		redirect: "Você será redirecionado para página principal.",
		needValidName: "Preencha o campo de nome corretamente",
		invalidEmail: "Por favor, informe um email válido.",
		invalidPhone: "Por favor, informe um número válido.",
	},
	enUS: {
		title: "Page not found",
		redirect: "You will be redirected to the main page.",
		default: "An error has ocurred, try again later.",
		needValidName: "Fill in the name field correctly",
		invalidEmail: "Please enter a valid email!",
		questionsMessage: "Please answer all questions!",
		invalidPhone: "Please, fill with a valid phone number",
		needLongerName: (numOfChars: number) => `Name needs to have more than ${numOfChars} characters.`,
	},
};
