// MARK: Resources
import { IProps as OgTagsProps } from "../../OgTags";

// MARK: Implementation
export const home = {
	ptBR: {
		ogTags: {
			type: "website",
			title: "StartApp | Desenvolvimento de Apps Mobile, Web e Dashboards",
			description: "Na StartApp temos seu time de desenvolvimento. Oferecemos o que você precisa para transformação digital, inovação do seu negócio e lançamento de startups.",
			themeColor: "#6a7488",
			keywords: [
				"Desenvolvimento de Aplicativos",
				"Sites Responsivos",
				"Progressive Web Apps",
				"PWA",
				"Webapps",
				"Tecnologia",
				"Digitalização",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		welComeSection: {
			methodology: "Metodologia",
			consulting: "Consultoria",
			home: "Home",
			customerArea: "Área do cliente",
			blog: "Blog",
			services: "Serviços",
			cases: "Cases",
			talkToUs: "Fale com a gente!",
			title: {
				mainText: "Transformamos sua ideia em ",
				coloredText: "business",
			},
			contact: {
				talkToYou: "Quer saber mais? Iremos entrar em contato com você!",
				talkToMe: "Fale comigo",
				addedContact: "Adicione seu contato para que possamos falar com você.",
				weWillNotShare: "Seu contato não será compartilhado!",
			},
			infoText: "Contamos com uma equipe especializada, cuidamos desde o design até o desenvolvimento e lançamento do seu projeto no mercado.",
			startProject: "Comece um projeto",
		},
		howDoSection: {
			whatWeDo: "O QUE FAZEMOS",
			title: "Nossos Serviços",
			textInfo: "Nossos principais serviços",
		},
		whatDoSection: {
			whatWeDo: "NOSSO D.N.A",
			title: "Somos uma equipe versátil e especializada",
			textInfo: "Composta por estrategistas de negócios, designers, desenvolvedores e growth hackers. Iremos oferecer todo o suporte para que seu projeto esteja sempre 100% atualizado. Não vendemos apenas desenvolvimento, realizamos o seu sonho.",
			buttonText: "Conheça a empresa",
			skills: {
				title1: "Desenvolvimento de aplicativos e soluções web",
				description1: "Criação de aplicativos nativos, entregando uma maior eficiência e alta gama de funcionalidades para os seus usuários. Sites com o melhor design e utilizando semântica e padrão de modelo HTML / CSS. ",
				title2: "Consultoria",
				description2: "Acelere seu projeto com ajuda de mentores. Nosso modelo de consultoria aceleradora, na área Tecnológica, Jurídica & Estratégica foca nos pilares fundamentais para alavancar seus resultados. Seja preciso e eficiente.",
				title3: "Design",
				description3: "Desenho de produtos digitais que materializam serviços e entregam experiências para os usuários. Atuamos from as pesquisas iniciais até o desenho das interfaces em alta fidelidade.",
			},
		},
		contactSection: {
			tag: "Desenvolvimento de aplicativos",
			title: "Fale com a gente!",
			subTitle: "Ainda tem dúvidas, não esqueça de ver um tópico separado para você, com as principais dúvidas sobre desenvolvimento de aplicativos.",
			question: "Quer iniciar um projeto?",
			talktoUs: "Fale com a nossa equipe agora mesmo!",
		},
		consulting: {
			greatTitle: "Consultoria",
			tagTitle: "COMO FAZEMOS",
			bookNow: "Agende agora",
			steps: [
				{
					title: "TECH",
					// tslint:disable-next-line: no-duplicate-string
					subtitle: "O digital ao seu favor",
					// tslint:disable-next-line: no-duplicate-string
					description: "Nossos Consultores buscam entender a sua realidade e Mercado, visando aplicar os melhores mecanismos tecnológicos para um resultado positivo. Com o Digital invadindo as atividades essenciais do cotidiano, quem não aposta em se reinventar perde para a concorrência. Não deixe seu competidor ser o primeiro.",
				},
				{
					title: "JURÍDICO",
					subtitle: "Prevenção & Informação",
					description: "Um serviço de apoio! Consiste, assim, em esclarecer questões ligadas ao universo do Direito e prestar informações e opiniões especializadas. Desta forma, há maior embasamento para tomada de decisões, protegendo-se de possíveis problemas futuros.",
				},
				{
					title: "ESTRATÉGICO",
					subtitle: "Torne seu projeto escalável",
					description: "Um conjunto de estratégias é um dos pilares mais importantes para quem deseja se manter ativo no mercado, entendemos o seu modelo de negócio e traçamos as principais atividades que farão a sua empresa decolar!",
				},
			],
		},
		methodology: {
			tagTitle: "COMO FAZEMOS",
			greatTitle: "Nossa Metodologia",
			paragraph: "Quer saber um pouco mais sobre a metodologia de criação e gestão que desenvolvemos para você? Acompanhe o passo a passo abaixo da construção do seu projeto.",
			steps: [
				{
					subtitle: "// COMO TUDO COMEÇA",
					title: "Conte-nos a sua ideia",
					description: "O primeiro ponto é você explicar o que busca. Após isso, através de nossa expertise, iremos desenvolver a solução que você precisa!",
				},
				{
					subtitle: "// WIREFRAME",
					title: "Desenvolvimento da estrutura",
					description: "Com base no que você procura, nossa equipe irá desenvolver toda a estrutura para que seu aplicativo ou/e PWA seja totalmente funcional, eficiente e seguro.",

				},
				{
					subtitle: "// DESIGN",
					title: "Criatividade tomando formas e cores",
					description: "De nada adianta uma estrutura funcional e estável, sem um design apropriado. Durante o desenvolvimento das aplicações, iremos criar um design eficiente, elegante e que vai tornar a experiência do seu usuário muito mais agradável.",
				},
				{
					subtitle: "// DESENVOLVIMENTO",
					title: "Mais do que linhas de código",
					description: "Desenvolvimento de aplicativos e PWA’s não se resumem apenas à parte técnica. Nós damos vida às ideias e através da tecnologia iremos desenvolver uma solução de forma estratégica e focada no crescimento do seu negócio.",
				},
				{
					subtitle: "// TESTES",
					title: "1, 2, 3... Testando",
					description: "Depois de tudo pronto, chegou a hora da validação. Você poderá testar o seu projeto em diferentes situações, para confirmar que tudo está funcionando perfeitamente. Os testes serão feitos cuidadosamente, para garantir 100% de funcionalidade!",
				},
				{
					subtitle: "// LANÇAMENTO",
					title: "Apertem os cintos!",
					description: "Chegou a grande hora! Lançar seu projeto é um grande momento pelo qual nós e você tanto trabalhamos. Iremos te ajudar com os mecanismos necessários para que a sua ideia seja um sucesso.",
				},
				{
					subtitle: "// MANUTENÇÃO E ATUALIZAÇÕES",
					title: "Quem ama, cuida.",
					description: "Quem disse que nosso trabalho acaba no lançamento? Após o desenvolvimento, nós iremos te oferecer todo o suporte que você precisa, para que seu aplicativo ou/e PWA seja atualizado e melhorado constantemente.",
				},
			],
		},
	},
	enUS: {
		ogTags: {
			type: "website",
			title: "StartApp | Mobile, Web e Dashboards development",
			description: "StartApp is your software development team. We offer everything you need to digitalize your business, innovate, and launch your startup ideas.",
			themeColor: "#6a7488",
			keywords: [
				"App Development",
				"Responsive Websites",
				"Progressive Web Apps",
				"PWA",
				"Webapps",
				"Technology",
				"Digitalization",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		welComeSection: {
			methodology: "Methodology",
			consulting: "Consulting",
			home: "Home",
			customerArea: "Customer's Area",
			blog: "Blog",
			services: "Services",
			cases: "Cases",
			talkToUs: "Talk to us!",
			title: {
				mainText: "We turn your idea into ",
				coloredText: "a business",
			},
			contact: {
				talkToYou: "Want to know more? We will get in touch with you!",
				talkToMe: "Talk to me",
				addedContact: "Want to know more? We will get in touch with you!",
				weWillNotShare: "Your contact will not be shared!",
			},
			infoText: "We have a specialized team that will take care of your project, from design to the development and launch of your project to the market.",
			startProject: "Start a project",
		},
		whatDoSection: {
			whatWeDo: "OUR D.N.A",
			title: "We are a versatile team",
			textInfo: "Composed of business strategists, designers, developers and growth hackers. offer offer all the support so that your project is always 100% updated. We don't just sell development, we make your dream come true.",
			buttonText: "know the company",
			skills: {
				title1: "Application development and web solutions",
				description1: "Creation of native applications, delivering greater efficiency and a high range of functionality to its users. Sites with the best design and using semantics and standard HTML / CSS template.",
				title2: "Business consulting",
				description2: "Accelerate your project with the help of mentors. Our accelerating consultancy model, in the Technological, Legal & Strategic area, focuses on the fundamental pillars to leverage its results. Be precise and efficient.",
				title3: "Design",
				description3: "Design of digital products that materialize services and deliver experiences to users. We operate from initial research to the design of high-fidelity interfaces.",
			},
		},
		howDoSection: {
			whatWeDo: "WHAT DO WE DO",
			title: "Our services",
			textInfo: "Our main services",
		},
		contactSection: {
			tag: "Desenvolvimento de aplicativos",
			title: "Talk to us!",
			subTitle: "Still not sure? don't forget to see the FAQ about app development",
			question: "Want to start a new project?",
			talktoUs: "talk to our team now!",
		},
		consulting: {
			greatTitle: "Consulting",
			tagTitle: "HOW WE DO",
			bookNow: "Book now",
			steps: [
				{
					title: "TECH",
					// tslint:disable-next-line: no-duplicate-string
					subtitle: "The digital in your favor",
					// tslint:disable-next-line: no-duplicate-string
					description: "Our Consultants seek to understand their reality and the Market, aiming to apply the best technological mechanisms for a positive result. With Digital invading essential daily activities, those who do not bet on reinventing themselves lose to the competition. Don't let your competitor be the first.",
				},
				{
					title: "LEGAL",
					subtitle: "Prevention & Information",
					description: "A support service! It consists, therefore, in clarifying issues related to the universe of Law and providing specialized information and opinions. Thus, there is a greater basis for decision making, protecting against possible future problems.",
				},
				{
					title: "STRATEGIC",
					subtitle: "Make your project scalable",
					description: "A set of strategies is one of the most important pillars for those who want to remain active in the market, we understand your business model and outline the main activities that will make your company take off!",
				},
			],
		},
		methodology: {
			tagTitle: "HOW WE DO",
			greatTitle: "Our Methodology",
			paragraph: "Do you want to know a little more about the creation and management methodology we developed for you? Follow the step by step below the construction of your project.",
			steps: [
				{
					subtitle: "// HOW EVERYTHING BEGINS",
					title: "Tell us your idea",
					description: "The first point is that you explain what you are looking for. After that, through our expertise, we will develop the solution you need!",
				},
				{
					subtitle: "// WIREFRAME",
					title: "Structure development",
					description: "Based on what you are looking for, our team will develop the entire structure so that your application or / and PWA is fully functional, efficient and secure.",

				},
				{
					subtitle: "// DESIGN",
					title: "Creativity taking shapes and colors",
					description: "There is no use in a functional and stable structure, without an appropriate design. During application development, we will create an efficient, elegant design that will make your user's experience much more pleasant.",
				},
				{
					subtitle: "// DEVELOPMENT",
					title: "More than code lines",
					description: "Application development and PWA’s are not just about the technical part. We bring ideas to life and through technology we will develop a solution strategically and focused on growing your business.",
				},
				{
					subtitle: "// TESTS",
					title: "1, 2, 3... Testing",
					description: "After everything is ready, it's time for validation. You will be able to test your project in different situations, to confirm that everything is working perfectly. The tests will be done carefully, to guarantee 100% functionality!",
				},
				{
					subtitle: "// LAUNCH",
					title: "Fasten your seat belts!",
					description: "The great time has come! Launching your project is a great moment for which we and you have worked so hard. We will help you with the necessary mechanisms to make your idea a success.",
				},
				{
					subtitle: "// MAINTENANCE AND UPGRADES",
					title: "Who loves, cares.",
					description: "Who said that our work ends at the launch? After development, we will offer you all the support you need, so that your application or / and PWA is constantly updated and improved.",
				},
			],
		},
	},
};
