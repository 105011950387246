// MARK: Next
import { NextPageContext, GetServerSidePropsContext } from "next";

// MARK: API
import * as api from "@startapp/startapp-user-ssr-api";

// MARK: Libs
import LocalizedStrings from "localized-strings";
import { default as AcceptLanguage } from "accept-language";
import { default as nookies } from "nookies";

// MARK: Resources
import { treatError } from "../ErrorHandler";
import { alerts } from "./alerts";
import { ogTags } from "./ogTags";
import { components } from "./components";
import { error } from "./error";
import { home } from "./pages/home";
import { general } from "./general";
import { cases } from "./pages/cases";
import { faq } from "./pages/faq";
import { blog } from "./pages/blog";
import { consulting } from "./pages/consulting";

// MARK: Implementation
const currencyBrl = (cents: number, options?: { showSymbol?: boolean, withCents?: boolean }) => {
	const withCents = options === undefined || options.withCents === undefined || options.withCents;
	const showSymbol = options === undefined || options.showSymbol === undefined || options.showSymbol;
	const numeroTokens: string[] = `${withCents ? (cents / 100).toFixed(2) : Math.round(cents / 100)}`.split(".");
	numeroTokens[0] = `${showSymbol ? "R$ " : ""}${numeroTokens[0].split(/(?=(?:...)*$)/).join(".")}`;
	return numeroTokens.join(",");
};

const localizedStrings = {
	"pt-BR": {
		alerts: alerts.ptBR,
		ogTags: ogTags.ptBR,
		components: components.ptBR,
		error: error.ptBR,
		pages: {
			consulting: consulting.ptBR,
			home: home.ptBR,
			cases: cases.ptBR,
			faq: faq.ptBR,
			blog: blog.ptBR,
		},
		general: general.ptBR,
		formatters: {
			currency: {
				BRL: currencyBrl,
			},
		},
	},
	"en-US": {
		alerts: alerts.enUS,
		ogTags: ogTags.enUS,
		components: components.enUS,
		error: error.enUS,
		general: general.enUS,
		pages: {
			consulting: consulting.enUS,
			home: home.enUS,
			cases: cases.enUS,
			faq: faq.enUS,
			blog: blog.ptBR,
		},
		formatters: {
			currency: {
				BRL: currencyBrl,
			},
		},
	},
};

const Strings = (options?: { acceptLanguage?: string | null, ctx?: NextPageContext | GetServerSidePropsContext }) => {
	const strings = new LocalizedStrings(localizedStrings);

	let acceptLanguage: string | null = options && options.acceptLanguage || null;

	const cookies = nookies.get(options ? options.ctx : undefined);

	if (!acceptLanguage && cookies.acceptLanguage) {
		acceptLanguage = cookies.acceptLanguage;
	}

	if (!acceptLanguage) {
		try {
			AcceptLanguage.languages(strings.getAvailableLanguages());

			acceptLanguage = options && options.ctx && options.ctx.req ?
				AcceptLanguage.get(options.ctx.req.headers["accept-language"] as string) :
				navigator.language;
		} catch (e) {
			treatError(strings, e);
		}
	}

	if (
		options &&
		options.ctx &&
		options.ctx.query.language &&
		strings.getAvailableLanguages().includes(options.ctx.query.language.toString())
	) {
		acceptLanguage = options.ctx.query.language.toString();
	}

	strings.setLanguage(acceptLanguage || strings.getLanguage());
	nookies.set(
		options ? options.ctx : undefined,
		"acceptLanguage",
		strings.getLanguage(),
		{
			path: "/",
		},
	);

	api.setStrings(strings);

	return strings;
};

export type StringsType = ReturnType<typeof Strings>;
export default Strings;
