export const general = {
	ptBR: {
		close: "Fechar",
		ok: "Ok",
		yes: "Sim",
		cases: "Projetos Lançados",
		no: "Não",
		cancel: "Cancelar",
		add: "Cadastrar",
		confirm: "Confirmar",
		loading: "Carregando",
		back: "Voltar",
		edit: "Editar",
		email: "E-mail",
		added: "Adicionado",
		removed: "Removido",
		copyLink: "Copiar link para Área de Transferência",
		name: "Nome",
		questions: "Questões",
		message: "Mensagem",
		send: "Enviar",
		phone: "Número de contato",
		thank: "Obrigado!",
		talkToUs: "Fale conosco",
		sideDrawer: "Menu Lateral",
	},
	enUS: {
		ok: "Ok",
		close: "Close",
		yes: "Yes",
		no: "No",
		cases: "Some of our Products",
		cancel: "Cancel",
		add: "Register",
		confirm: "Confirm",
		loading: "Loading",
		back: "Back",
		edit: "Edit",
		email: "E-mail",
		added: "Added",
		removed: "Removed",
		copyLink: "Copy link to Transfer Area",
		name: "Name",
		questions: "Questions",
		message: "Message",
		send: "Send",
		phone: "Contact Number",
		thank: "Thank You!",
		talkToUs: "Talk to Us",
		sideDrawer: "Side Menu",
	},
};
