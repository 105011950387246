// MARK: Resources
import { IProps as OgTagsProps } from "../../OgTags";

// MARK: Implementation
export const faq = {
	ptBR: {
		ogTags: {
			type: "website",
			title: "StartApp | Perguntas Frequentes sobre nosso trabalho",
			description: "Aqui você encontra tudo sobre como funciona o desenvolvimento na StartApp. Quanto tempo leva, como trabalhamos, direitos sobre o código e muito mais!",
			themeColor: "#131c2e",
			keywords: [
				"Como funciona",
				"FAQ",
				"Desenvolvimento na StartApp",
				"O código é meu",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		faqPage: {
			titleAcronym: "FAQ",
			faqPageTitle: "Perguntas Frequentes",
			faqParagraph: "Separamos aqui algumas dúvidas recorrentes, que também pode ser a sua.",
			steps: [
				{
					faqTitle: "Quanto tempo leva para desenvolver um aplicativo?",
					faqDescription: "O tempo de desenvolver um aplicativo varia de 1 a 4 meses, a depender da complexidade do projeto. Caso precise de um desenvolvimento emergencial, fala com a gente! Vamos fazer de tudo para atender seu pedido",
				},
				{
					faqTitle: "Como vocês trabalham?",
					faqDescription: "Começamos o desenvolvimento do seu produto com o design de todas as telas, priorizando a usabilidade e experiência do usuário. Uma vez que o design for aprovado por você, nós passamos para o desenvolvimento do código de fato. Com o código pronto, passamos para a etapa de testes para identificar possíveis bugs e então, lançamos seu produto. Se tratando de um app, configuramos ele nas lojas. ",
				},
				{
					faqTitle: "Existem custos fixos?",
					faqDescription: "Seu único custo fixo é o de servidor, que é quem hospeda o seu app ou site. Esse custo varia proporcionalmente com a quantidade de dados que é armazenada no sistema. Caso você queira que o aplicativo fique na sua loja da Apple, existe um custo fixo anual que deve ser pago para Apple diretamente, e, no caso do Google, um custo que será pago uma vez apenas.",
				},
				{
					faqTitle: "Vocês oferecem algum suporte?",
					faqDescription: "Nós entendemos que com o uso é possível aparecem bugs e nós damos 3 meses de garantia para corrigir essas eventualidades! Quando esse período acabar nós oferecemos a opção de contratação por hora, que pode ser utilizada tanto para desenvolvimento de novas funcionalidades quanto para correção de algum ponto do aplicativo.",
				},
				{
					faqTitle: "O que seria um aplicativo personalizado?",
					faqDescription: "Não temos um modelo pré pronto para \"encaixar\" o seu projeto. Nós desenhamos página por página de acordo com o que você quer, levando em consideração a dinamicidade e experiência do usuário.",
				},
				{
					faqTitle: "Estou preso a vocês de alguma forma?",
					faqDescription: "Não! O código que for escrito é seu e pode ser desenvolvido com a nossa equipe, com uma equipe própria sua ou com outra empresa, sem nenhum pagamento de mensalidade para nossa empresa.",
				},
			],
		},
	},
	enUS: {
		ogTags: {
			type: "website",
			title: "StartApp | Frequently asked questions about our work",
			description: "Here you can find everything about how development at StartApp works. How long it takes, code ownership, and much more!",
			themeColor: "#131c2e",
			keywords: [
				"How it works",
				"FAQ",
				"Development at StartApp",
				"Code ownership",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		faqPage: {
			titleAcronym: "FAQ",
			faqPageTitle: "Frequently Asked Questions",
			faqParagraph: "Here we separate some recurring doubts, which can also be yours.",
			steps: [
				{
					// tslint:disable-next-line: no-duplicate-string
					faqTitle: "How much cost to develop an app?",
					// tslint:disable-next-line: no-duplicate-string
					faqDescription: "Our expense calculator can help you to get an estimate in less than 1 minute!Our expense calculator can help you to get an estimate in less than 1 minute!",
				},
				{
					faqTitle: "How much cost to develop an app?",
					faqDescription: "Our expense calculator can help you to get an estimate in less than 1 minute!Our expense calculator can help you to get an estimate in less than 1 minute!",
				},
				{
					faqTitle: "How much cost to develop an app?",
					faqDescription: "Our expense calculator can help you to get an estimate in less than 1 minute!Our expense calculator can help you to get an estimate in less than 1 minute!",
				},
				{
					faqTitle: "How much cost to develop an app?",
					faqDescription: "Our expense calculator can help you to get an estimate in less than 1 minute!Our expense calculator can help you to get an estimate in less than 1 minute!",
				},
				{
					faqTitle: "How much cost to develop an app?",
					faqDescription: "Our expense calculator can help you to get an estimate in less than 1 minute!Our expense calculator can help you to get an estimate in less than 1 minute!",
				},
				{
					faqTitle: "How much cost to develop an app?",
					faqDescription: "Our expense calculator can help you to get an estimate in less than 1 minute!Our expense calculator can help you to get an estimate in less than 1 minute!",
				},
			],
		},
	},
};
