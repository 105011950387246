// MARK: Resources
import { IProps as OgTagsProps } from "../../OgTags";

// MARK: Implementation
export const cases = {
	ptBR: {
		ogTags: {
			type: "website",
			title: "StartApp | Projetos de Sucesso com Apps Mobile, Web e Dashboards",
			description: "Lista com projetos de sucesso desenvolvidos na StartApp. Projetos com funcionalidades variadas desenvolvidos por um time com mais de 5 anos de experiência.",
			themeColor: "#131c2e",
			keywords: [
				"Cases de sucesso",
				"Portifólio",
				"Startups",
				"Nossos trabalhos",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		tagTitle: "O QUE FIZEMOS",
		title: "Cases de Sucesso",
		paragraph: "Conheça alguns dos projetos que lançamos",
	},
	enUS: {
		ogTags: {
			type: "website",
			title: "StartApp | Our work with Mobile, Web and Dashboards",
			description: "Successful projects we had at StartApp. Projects with diverse features developed by a team with vast experience working in tech over the past 5 years.",
			themeColor: "#131c2e",
			keywords: [
				"Successful projects",
				"Diverse features",
				"Startups",
				"Our work",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		tagTitle: "WHAT WE DID",
		title: "Launched Projects",
		paragraph: "Some of projects we launched.",
	},
};
