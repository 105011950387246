// MARK: Resources
import { IProps as OgTagsProps } from "../../OgTags";

// MARK: Libs
import moment from "moment";
import "moment/locale/pt-br";

// MARK: Implementation
export const blog = {
	ptBR: {
		ogTags: {
			type: "website",
			title: "StartApp | Blog",
			description: "Uma equipe pronta para te ajudar nos seus projetos de Tecnologia, com experiência em Tecnologia, Negócios e experiência com Startups.",
			themeColor: "#131c2e",
			keywords: [
				"Blog",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		blogPage: {
			title: "Blog",
			titleHeader: (name: string) => `StartApp | ${name}`,
			daysAgo: (date: string) => {
				const momentDate = moment(date);
				return momentDate.fromNow().toUpperCase();
			},
			date: (date: string) => {
				const momentDate = moment(date);
				momentDate.locale("pt");
				return momentDate.format("L");
			},
			point: "•",
			blogHeader: {
				home: "HOME",
				author: "AUTOR",
				blogStartapp: "Blog da StarApp",
			},
			authorDescription: {
				location: "Brasil",
				post: (amountPost: number) => `${amountPost} ${amountPost === 1 ? "post" : "posts"}`,
			},
		},
	},
};
