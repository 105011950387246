// MARK: Resources
import { IProps as OgTagsProps } from "../../OgTags";

// MARK: Implementation
export const consulting = {
	ptBR: {
		ogTags: {
			type: "website",
			title: "StartApp | Consultoria em Tecnologia, Negócios e Startups",
			description: "Uma equipe pronta para te ajudar nos seus projetos de Tecnologia, com experiência em Tecnologia, Negócios e experiência com Startups.",
			themeColor: "#131c2e",
			keywords: [
				"Consultoria",
				"Tecnologia",
				"Negócios",
				"Startups",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
	},
	enUS: {
		ogTags: {
			type: "website",
			title: "StartApp | Consulting in Technology, Business and Startups",
			description: "A well-rounded team ready to help you with your tech project, with professionals experienced in Technology, Business, and experience with Startups.",
			themeColor: "#131c2e",
			keywords: [
				"Consulting",
				"Technology",
				"Business",
				"Startups",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "startapp.one"}/static_icon_1024.png`,
				type: "image/png",
				width: 1024,
				height: 1024,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
	},
};
